<template lang="pug">
svg.svg-icon(xmlns="http://www.w3.org/2000/svg" :viewBox="viewBox" v-html="svg.innerHTML" :class="{'isArrow': isArrow}")
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  export default defineComponent({
    props: {
      name: {
        type: String,
        required: true,
      },
      isArrow: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        svg: '' as string,
        viewBox: '0 0 24 24' as string,
        nameMutable: this.name as string,
      };
    },
    watch: {
      name(): void {
        this.nameMutable = this.name;
        this.getSVG();
      },
    },
    created(): void {
      this.getSVG();
    },
    methods: {
      getSVG(): void {
        if (this.nameMutable == '') {
          this.svg.innerHTML = '';
        } else {
          fetch(
            `https://api.cloudly.space/static/assets/products-icons/roadbook/${this.nameMutable}.svg`,
          )
            .then((resp) => resp.text())
            .then((body) => {
              const svg = document.createElement('div');
              svg.innerHTML = body;

              this.svg = svg.querySelector('svg');
              this.viewBox = this.svg.getAttribute('viewBox');
            })
            .catch((err) => console.warn(err));
        }
      },
    },
  });
</script>

<style lang="postcss">
  .svg-icon {
    @apply h-[20px] fill-black flex justify-center items-center;

    &.isArrow,
    &.isArrow path {
      stroke: theme('colors.light-grey') !important;
      stroke-width: 2px !important;
    }
  }
</style>
