<template lang="pug">
.date-range-picker(v-if="value" :disabled="disabled")
  .header
    slot(name="title")
    .facet-name.sub-title-container(v-if="!clientInfoField")
      .sub-title
        span.main-language {{ title[language] || title[native] || 'Période' }}
        i.default-language
          rc-translate.default-language( :value="title[native] || 'Période'" )
  .presets(v-if="!disabled && useDefaultPresets")
    .preset.click(v-for="(preset, idx) in presets" :key="idx" @click="executePreset(preset, idx)" :active="isActive(preset,valueMutable)")
      .text(:class="{'multiple': language != native}")
        .main-language {{ this.languages[language]['date_presets'][preset.name] }}
        rc-translate.default-language(:value="$t(`date_presets.${preset.name}`, native)" )
    .preset.click(@click="addNewPreset()")
      .dates-add
        rc-svg.svg-add(name="calendar")

  .custom-presets(v-if="!isPeriodMobile")
    .custom-preset.click(v-for="(preset, idx) in filteredDates" :key="idx" :active="isActive(preset,valueMutable) || isRoadbookPeriod(preset)" :data-index='idx')
      .text(v-if="!disabled && hasCustomPreset && !(lightStyle && !isActive(preset,valueMutable)) && preset.from && preset.to" :class="{lightStyle}")
        .main-language(v-if="isFixedPreset(preset) && !useDefaultPresets" @click="!lightStyle ? executeCustomPreset(preset, idx):  addNewPreset()") {{ $t(`date_presets.${handleFixedPresetDisplay(preset)}`) }}
        .main-language(v-else-if="isSameDay(preset)" @click="!lightStyle ? executeCustomPreset(preset, idx) : addNewPreset()") {{ `${capitalize($moment(preset.from).locale(language).format('DD MMM YYYY'))}`}}
        .main-language(v-else="!isSameDay(preset)" @click="!lightStyle ? executeCustomPreset(preset, idx) : addNewPreset()") {{ `${capitalize($moment(preset.from).locale(language).format('DD MMM YYYY'))}` }} - {{ `${capitalize($moment(preset.to).locale(language).format('DD MMM YYYY'))}`}}
        .edit-pen-decoration(v-if="editable")
          rc-svg.edit-pen(name="pen" v-show="isActive(preset,valueMutable)" @click="editCustomPreset(preset, idx)")
          rc-svg.close(name="delete" v-show="!isActive(preset,valueMutable)" @click="removePreset(idx)")
    .custom-preset(v-if="!disabled && !useDefaultPresets")
      .dates-add(@click="addNewPreset()" :class="{lightStyle}")
        rc-svg.svg-add(name="calendar")

  .custom-presets-mobile(v-else-if="(checkOnDevice()[0]||checkOnDevice()[2]) && customPresets.length" :class="!isRoadbookPeriod(filteredDates[0]) ? 'unselected' : ''")
      .custom-preset-mobile(v-if="isSameDay(filteredDates[0])" @click="!isRoadbookPeriod(filteredDates[0]) ? setCustomPreset(filteredDates[0]) : addNewPreset()" :class="!isRoadbookPeriod(filteredDates[0]) ? 'unselected' : ''") {{ `${capitalize($moment(filteredDates[0].from).locale(language).format('DD/MM'))}` }}
      .custom-preset-mobile(v-else @click="!isRoadbookPeriod(filteredDates[0]) ? setCustomPreset(filteredDates[0]) : addNewPreset()" :class="!isRoadbookPeriod(filteredDates[0]) ? 'unselected' : ''") {{ $t('date_presets.from') + `${capitalize($moment(filteredDates[0].from).locale(language).format('DD/MM'))}` + ' ' + $t('date_presets.to') + `${capitalize($moment(filteredDates[0].to).locale(language).format('DD/MM'))}`}}
      .custom-preset-mobile(v-if="!disabled && !useDefaultPresets")
      .dates-add-mobile(@click="addNewPreset()" :class="{lightStyle}")
        rc-svg.svg-add(name="calendar")
  .custom-presets-empty(v-else @click="addNewPreset()")
    span {{ $t('date_presets.custom') }}
    .custom-preset-mobile(v-if="!disabled && !useDefaultPresets")
      .dates-add-mobile(@click="addNewPreset()" :class="{lightStyle}")
        rc-svg.svg-add(name="calendar")
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import RcTranslate from '@/components/data_displays/RcTranslate.vue';
  import mixin from '@/utils/mixins/RcMixin.vue';
  import RcCalendarMixin from '@/utils/mixins/RcCalendar.mixin.vue';
  import RcSvg from '@/components/medias/RcSvg.vue';
  import { useLeafletsStore, useRoadbookStore, usePlaylistStore, useCalendarStore } from '@/store';
  import { Facet } from '@/models/interfaces';
  import { Moment } from 'moment';
  import { fr, en, es, de } from '@/i18n';

  export default defineComponent({
    setup() {
      const languages = {
        fr: fr,
        en: en,
        es: es,
        de: de,
      };
      return { languages };
    },
    name: 'RcDateRangePicker',
    components: {
      RcTranslate,
      RcSvg,
    },
    mixins: [mixin, RcCalendarMixin],
    props: {
      value: {
        type: Object,
        default() {
          return {
            from: null,
            to: null,
          };
        },
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      useDefaultPresets: {
        type: Boolean,
        default: true,
      },
      editable: {
        type: Boolean,
        default: true,
      },
      clientInfoField: {
        type: Boolean,
        default: false,
      },
      lightStyle: {
        type: Boolean,
        default: false,
      },
      isPeriodMobile: {
        type: Boolean,
        default: false,
      },
      title: {
        type: Object,
        default: null,
      },
    },
    emits: ['scrollCalendar'],
    data() {
      return {
        dropdownCoordinates: { top: null, left: null } as { from?: string; to?: string },
        valueMutable: null,
      };
    },
    computed: {
      filteredDates(): Array<{ from?: string; to?: string }> {
        return this.useDefaultPresets
          ? this.filteredPresets(usePlaylistStore().datePresets)
          : usePlaylistStore().datePresets;
      },
      language(): string {
        return useRoadbookStore().metadata.visitor.language;
      },
      hasCustomPreset(): number {
        return usePlaylistStore().datePresets.length;
      },
      customPresets(): Array<{ from?: number; to?: number }> {
        return usePlaylistStore().datePresets;
      },
      datesEditing(): boolean {
        return useCalendarStore().datesEditing;
      },
      visibleCalendars: {
        get(): boolean {
          return useCalendarStore().opened;
        },
        set(value: boolean): void {
          useCalendarStore().toggleOpened(value);
        },
      },
      currentDate(): { from?: Moment | null; to?: Moment | null } {
        return useCalendarStore().currentValue || {};
      },
      lastActiveIndex(): string {
        return useCalendarStore().lastActiveIndex;
      },
      lastActiveFixedPreset(): string {
        return useCalendarStore().lastActiveFixedPreset;
      },
      lastDatePicked(): { from: string; to: string } {
        return useLeafletsStore().lastDatePicked;
      },
    },
    watch: {
      currentDate: {
        handler() {
          this.valueMutable.from = this.currentDate.from;
          this.valueMutable.to = this.currentDate.to;
        },
      },
    },
    mounted(): void {
      this.removeDropdown();
      this.valueMutable = this.value;
      if (
        this.lastDatePicked &&
        !this.currentDate?.from &&
        !this.currentDate?.to &&
        !this.clientInfoField
      ) {
        useCalendarStore().setCurrentValue({
          from: this.lastDatePicked.from,
          to: this.lastDatePicked.to,
        });
      }

      const selectedFacet: Array<Facet> = usePlaylistStore().selected.facets || [];
      if (selectedFacet) {
        Object.entries(selectedFacet).forEach((facet) => {
          if (facet[1].start && facet[1].end) {
            if (!this.customPresets.some((preset) => preset == facet[1])) {
              if (!this.customPresets.includes(facet[1])) {
                usePlaylistStore().addDatePreset(facet[1]);
              }
            }
          }
        });
      }

      if (this.valueMutable.from && this.valueMutable.to && !this.clientInfoField) {
        this.defineCurrentValueIndex(this.filteredDates);
      }

      if (this.clientInfoField) {
        useCalendarStore().setLastActiveIndex('');
      }
    },
    unmounted(): void {
      if (this.clientInfoField) {
        this.emptyDate();
      }
    },
    methods: {
      editCustomPreset(preset: Array<{ from?: string; to?: string }>, idx: number): void {
        this.toggleCalendars();
        this.defineCurrentValue(preset);
        this.setActiveIndex(idx);
        useCalendarStore().setDatesEditing(true);
      },
      toggleCalendars(): void {
        if (!this.visibleCalendars) {
          setTimeout(() => {
            document.addEventListener('click', this.removeDropdown, true);
          }, 200);
          this.handleCalendarPosition();
        } else {
          document.removeEventListener('click', this.removeDropdown, true);
        }
        this.$nextTick(() => {
          this.visibleCalendars = !this.visibleCalendars;
        });
      },
      removeDropdown(e: MouseEvent): void {
        const dropdown: Element | null = document.querySelector('.dates-dropdown');
        if (this.visibleCalendars && !dropdown?.contains(e.target as Node)) {
          e.stopPropagation();
          if (this.lastActiveFixedPreset !== '') {
            this.executePreset(
              this.presets[Number(this.lastActiveFixedPreset)],
              Number(this.lastActiveFixedPreset),
            );
          }
          if (this.lastActiveIndex !== '') {
            if (!this.isActive(this.filteredDates[this.lastActiveIndex], this.valueMutable)) {
              this.executeCustomPreset(
                this.filteredDates[this.lastActiveIndex],
                this.lastActiveIndex,
              );
            }
          }
          this.visibleCalendars = false;
          useCalendarStore().setDatesEditing(false);
        }
        document.removeEventListener('click', this.removeDropdown, true);
      },
      executePreset(preset: { from?: string; to?: string }, idx: number): void {
        this.visibleCalendars = false;
        if (this.isActive(preset, this.valueMutable)) {
          this.emptyDate();
        } else {
          this.defineCurrentValue(preset);
          useCalendarStore().setLastActiveFixedPreset(idx.toString());
          useCalendarStore().setLastActiveIndex('');
        }
        this.validateDates();
      },
      executeCustomPreset(preset: { from?: string; to?: string }, idx: number): void {
        if (preset && preset.from && preset.to) {
          if (this.isActive(preset, this.valueMutable) && !this.visibleCalendars) {
            this.emptyDate();
          } else if (
            this.clientInfoField &&
            this.isRoadbookPeriod(preset) &&
            !this.visibleCalendars
          ) {
            this.emptyDate();
          } else {
            this.setActiveIndex(idx);
            useCalendarStore().setLastActiveFixedPreset('');
            this.defineCurrentValue(preset);
          }
          this.validateDates();
        }
      },
      setActiveIndex(index: number): void {
        useCalendarStore().setLastActiveIndex(index.toString());
      },
      removePreset(idx: number): void {
        const indexToDelete: number = this.customPresets.findIndex(
          (preset: { from?: string; to?: string }) => preset === this.filteredDates[idx],
        );
        usePlaylistStore().removeDatePreset(indexToDelete);
      },
      emptyDate(): void {
        useCalendarStore().setLastActiveIndex('');
        useCalendarStore().setLastActiveFixedPreset('');
        useCalendarStore().setCurrentValue({ from: null, to: null });
      },
      isSameDay(preset: { from?: string; to?: string }): boolean {
        const from: Moment =
          typeof preset.from === 'string' ? this.$moment(preset.from) : (preset.from as any)();
        const to: Moment =
          typeof preset.to === 'string' ? this.$moment(preset.to) : (preset.to as any)();
        return from.isSame(to, 'day');
      },
      addNewPreset(): void {
        useCalendarStore().setCurrentValue({ from: null, to: null });
        this.$nextTick(() => {
          this.toggleCalendars();
        });
      },
      validateDates(): void {
        this.clientInfoField
          ? this.validateRoadbookPeriod()
          : useCalendarStore().setDatesAsFilter(true);
      },
      validateRoadbookPeriod(): void {
        this.$nextTick(() => {
          if (this.lastActiveIndex !== '') {
            useRoadbookStore().updateMetadata({
              category: 'trip',
              field: 'period',
              value: this.customPresets[this.lastActiveIndex],
            });
          } else {
            useRoadbookStore().updateMetadata({
              category: 'trip',
              field: 'period',
              value: {
                from: '',
                to: '',
              },
            });
          }
        });
      },
      isRoadbookPeriod(preset): boolean | undefined {
        if (
          this.clientInfoField &&
          preset &&
          useRoadbookStore().metadata.trip.period?.from &&
          useRoadbookStore().metadata.trip.period?.to
        ) {
          return (
            useRoadbookStore().metadata.trip.period.from == preset.from &&
            useRoadbookStore().metadata.trip.period.to == preset.to
          );
        }
      },
      openCenteredCalendar(): void {
        this.dropdownCoordinates.left = (window.innerWidth - 630) / 2; //calendar width
        this.dropdownCoordinates.top = (window.innerHeight - 430) / 2; //calendar height
      },
      isCalendarScrollable(position: number): boolean {
        return position + 430 < window.innerHeight;
      },
      hasSufficentScrollable(top: number): boolean {
        const facetsHeight: number =
          document.querySelector('#facets')?.getBoundingClientRect().height || 0;
        return top + 430 < facetsHeight;
      },
      handleCalendarPosition(): void {
        const presets: HTMLCollection = document.getElementsByClassName('custom-preset');
        const activePreset: Array<Element> = Array.from(presets).filter((element) =>
          element.hasAttribute('active'),
        );

        if (this.clientInfoField) {
          this.openCenteredCalendar();
        } else {
          const hasVBar = (
            document
              .querySelector('#facets-wrapper .ps')
              ?.querySelector('.ps__thumb-y') as HTMLElement
          ).offsetHeight;
          const emptyPreset: Element | null = document.querySelector('.empty-preset');
          const basePresets: HTMLCollection = document.getElementsByClassName('preset');
          const dropdownLeft: number = Array.from(basePresets)[0]?.getBoundingClientRect().left - 6;
          this.dropdownCoordinates.left = dropdownLeft;

          if (activePreset.length) {
            const scrollingTop: number = Math.floor(activePreset[0]?.getBoundingClientRect().top);
            const calendarTop: number =
              activePreset[0]?.getBoundingClientRect().height +
              (document.querySelector('#results-count')?.getBoundingClientRect().bottom || 0) +
              28;
            if (!this.isCalendarScrollable(calendarTop)) {
              this.openCenteredCalendar();
            } else {
              this.$nextTick(() => {
                if (hasVBar && this.hasSufficentScrollable(calendarTop)) {
                  this.$emit('scrollCalendar', scrollingTop);
                  this.dropdownCoordinates.top = calendarTop;
                } else if (!hasVBar) {
                  this.dropdownCoordinates.top =
                    activePreset[0]?.getBoundingClientRect().bottom + 8;
                } else {
                  this.openCenteredCalendar();
                }
              });
            }
          } else if (!activePreset.length && emptyPreset) {
            const calendarTop =
              emptyPreset?.getBoundingClientRect().height +
              (document.querySelector('#results-count')?.getBoundingClientRect().bottom || 0) +
              28;
            if (!this.isCalendarScrollable(calendarTop)) {
              this.openCenteredCalendar();
            } else {
              if (hasVBar && this.hasSufficentScrollable(calendarTop)) {
                const scrollingTop = emptyPreset?.getBoundingClientRect().top;
                this.$emit('scrollCalendar', scrollingTop);
                this.dropdownCoordinates.top = calendarTop;
              } else {
                const scrollingTopAlt = emptyPreset?.getBoundingClientRect().bottom;
                this.dropdownCoordinates.top = scrollingTopAlt + 10;
              }
            }
          } else {
            const datesAddHeight: number =
              document.querySelector('.svg-add')?.getBoundingClientRect().height || 0;
            const calendarTop: number =
              (document.querySelector('#results-count')?.getBoundingClientRect().bottom || 0) +
              datesAddHeight +
              54;
            if (!this.isCalendarScrollable(calendarTop)) {
              this.openCenteredCalendar();
            } else {
              if (hasVBar && this.hasSufficentScrollable(calendarTop)) {
                const scrollingTop: number =
                  (document.querySelector('.svg-add')?.getBoundingClientRect().top || 0) - 20;
                this.$emit('scrollCalendar', scrollingTop);
                this.dropdownCoordinates.top = calendarTop;
              } else {
                const scrollingTopAlt = Array.from(basePresets)[0]?.getBoundingClientRect().bottom;
                this.dropdownCoordinates.top = scrollingTopAlt + 8;
              }
            }
          }
        }
        useCalendarStore().setPosition(this.dropdownCoordinates);
      },
      handleFixedPresetDisplay(preset: { from?: string; to?: string }): string | undefined {
        const fixedPresets: Array<{ from: string; to: string; name: string }> = this.presets;
        if (preset.from && preset.to) {
          let displayedName: string | null = null;
          fixedPresets.forEach((fixedPreset: { from: any; to: any; name: string }) => {
            if (
              this.$moment(preset.from).date() == fixedPreset.from().date() &&
              this.$moment(preset.to).date() == fixedPreset.to().date() &&
              this.$moment(preset.from).month() == fixedPreset.from().month()
            ) {
              displayedName = fixedPreset.name;
            } else if (
              this.$moment(preset.from).isSame(fixedPreset.from()) &&
              this.$moment(preset.to).isSame(fixedPreset.to())
            ) {
              displayedName = fixedPreset.name;
            } else {
              return;
            }
          });
          return displayedName || '';
        }
      },
      setCustomPreset(preset: { from?: string; to?: string }): void {
        useRoadbookStore().updateMetadata({
          category: 'trip',
          field: 'period',
          value: preset,
        });
      },
    },
  });
</script>

<style lang="postcss" scoped>
  .presets {
    @apply flex flex-wrap mr-[-6px] gap-y-[14px] max-w-full;

    .preset {
      @apply facet-chip-layer flex justify-center text-[15px] transition duration-300 ease-in-out;
      @apply border-none rounded-none m-0 mr-[14px];
      background: none;

      .text {
        @apply flex items-center border-[1px] border-solid border-grey flex-col rounded-small text-grey box-border px-[8px] py-[4px] transition duration-300 ease-in-out;

        .main-language {
          @apply m-auto transition duration-300 ease-in-out;
        }

        &.multiple {
          .main-language {
            @apply mt-auto;
          }

          .default-language {
            @apply mb-auto;
          }
        }
      }

      .preset-border {
        @apply absolute top-[30%] left-[-1px] w-[1px] h-[40%];
      }

      @media (hover: hover) {
        &:hover {
          .text {
            @apply bg-primary border-grey transition duration-300 ease-in-out;

            .main-language,
            .default-language {
              color: theme('colors.white') !important;
            }
            .preset-border {
              @apply text-secondary-blue;
            }
          }
        }
      }

      &[active='true'] {
        .text {
          @apply border-grey bg-primary transition duration-300 ease-in-out;

          .main-language,
          .default-language {
            color: theme('colors.white') !important;
          }
          .preset-border {
            @apply text-secondary-blue;
          }
        }
      }
      .text {
        .main-language {
          @apply text-[16px] text-center;
        }
        .default-language {
          @apply font-light text-middle-grey italic text-[12px];
        }
      }
    }
  }
  .custom-presets-empty {
    @apply flex flex-row border border-grey rounded-md items-center py-2 pl-2;

    span {
      @apply text-sm text-center;
    }
    .dates-add-mobile {
      @apply flex justify-center items-center w-[38px] h-[15px];

      .svg-add {
        @apply w-[20px] h-[20px] relative fill-primary px-[8px] py-[4px] transition duration-300 ease-in-out box-content;
      }

      &.lightStyle {
        .svg-add {
          @apply w-5;
        }
      }
      @media (hover: hover) {
        &:hover {
          @apply bg-primary;
          .svg-add {
            fill: theme('colors.white') !important;
          }
          &.lightStyle {
            .svg-add {
              @apply fill-secondary-blue !important;
            }
          }
        }
      }
    }
  }
  .unselected {
    @apply !text-grey !border-grey;
  }
  .custom-presets-mobile {
    @apply flex flex-row border border-primary rounded-md items-center py-2 pl-2 max-w-full;
    .custom-preset-mobile {
      @apply text-sm text-primary text-center;
    }

    .dates-add-mobile {
      @apply flex justify-center items-center w-[38px] h-[15px];

      .svg-add {
        @apply w-[20px] h-[20px] relative fill-primary px-[8px] py-[4px] transition duration-300 ease-in-out box-content;
      }

      &.lightStyle {
        .svg-add {
          @apply w-5;
        }
      }
      @media (hover: hover) {
        &:hover {
          @apply bg-primary;
          .svg-add {
            fill: theme('colors.white') !important;
          }
          &.lightStyle {
            .svg-add {
              @apply fill-secondary-blue !important;
            }
          }
        }
      }
    }
  }
  .custom-presets {
    @apply flex justify-start items-center flex-wrap pb-[8px] mr-[-6px];
    @media screen and (max-width: 950px) {
      @apply max-w-full;
    }

    .custom-preset {
      @apply flex justify-center outline-none mt-[10px] bg-none text-center relative text-[16px] transition duration-300 ease-in-out;
      font-family: Poppins;

      .text {
        @apply flex flex-row rounded-small border-solid border-grey border-[1px] items-center justify-center mr-[14px] px-[8px] py-[4px] text-grey bg-white transition duration-300 ease-in-out;
        &.row {
          @apply flex-col;
        }

        .edit-pen-decoration {
          @apply relative mr-[5px] z-[5] left-[8px] h-[23px] w-[22px] rounded-[50%];

          @media (hover: hover) {
            &:hover {
              @apply bg-secondary-blue;
            }
          }
        }

        .edit-pen {
          @apply absolute top-[4px] left-[4px] z-[5] h-[14px];
        }

        .close {
          @apply ml-[5px] mt-[4px] h-[14px] fill-grey transition duration-300 ease-in-out;
        }
      }

      .preset-border {
        @apply absolute top-[30%] left-[-1px] w-[1px] h-[40%];
      }

      @media (hover: hover) {
        &:hover {
          .text {
            @apply bg-primary border-grey transition duration-300 ease-in-out;
          }

          .close {
            @apply fill-white;
          }

          .main-language,
          .default-language {
            @apply text-white;
          }

          .preset-border {
            @apply text-secondary-blue;
          }
        }
      }

      &[active='true'] {
        @apply z-[5];
        .close {
          @apply fill-white;
        }

        .edit-pen {
          @apply fill-white;
        }

        .text {
          @apply bg-primary border-grey transition duration-300 ease-in-out;
        }

        .main-language,
        .default-language {
          @apply text-white;
        }
        .preset-border {
          @apply text-secondary-blue;
        }
      }
      .main-language {
        @apply font-normal;
      }
      .default-language {
        @apply font-light text-middle-grey text-[12px] italic;
      }

      .lightStyle {
        @apply border-none mr-0;
        background-color: inherit !important;
        .main-language {
          @apply text-black;
        }
      }
    }
  }
</style>

<style lang="postcss">
  .dates-add {
    @apply flex justify-center items-center border-[1px] border-solid border-primary rounded-[6px] transition duration-300 ease-in-out cursor-pointer w-[38px];

    .svg-add {
      @apply w-[22px] h-[22px] relative fill-primary px-[8px] py-[4px] transition duration-300 ease-in-out box-content;
    }

    &.lightStyle {
      .svg-add {
        @apply w-5;
      }
    }
    @media (hover: hover) {
      &:hover {
        @apply bg-primary;
        .svg-add {
          fill: theme('colors.white') !important;
        }
        &.lightStyle {
          .svg-add {
            @apply fill-secondary-blue !important;
          }
        }
      }
    }
  }
</style>
