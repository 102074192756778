<template lang="pug">
#custom-client-infos-field(:class="label")
  //- Can't use v-select because this library doesn't support option group, use custom selector to avoid trouble with iOS native select
  .select.custom-collapse(ref="select")
    slot(v-for="(item, index) in customOptions" :key="index")
      .optgroup( v-if="!isString(item.label.value)")
        .label {{ item.label.label.default }}
        .option(v-for="(subItem, index2) in item.label.value" :value="subItem.label.default" @click="selectValue(subItem)" :key="index2") {{ subItem.label.default }}
      .option.small(v-else :value="item.label.label.default" @click="selectValue(item.label)") {{ item.label.label.default }}
    .option.disabled(v-if="customOptions.length == 0") {{ $t('no_results') }}
  input.search(ref="input" @focus="openSelect" @input="updateOptions" @click="resetOptions" :placeholder="placeholder")
  .icons
    rc-svg.close(name="croix" @click="resetSearch")
    .right-arrow(@click="rotate")
      rc-svg.open(name="left" ref="turnable")
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import RcSvg from '@/components/medias/RcSvg.vue';
  import { useRoadbookStore } from '@/store/roadbook';
  import { ClientInfos, Info, Infos } from '@/models/interfaces/ClientInfos.interface';

  export default defineComponent({
    components: {
      RcSvg,
    },
    props: {
      label: {
        type: String,
        required: true,
      },
      entries: {
        type: Object as PropType<ClientInfos>,
        required: true,
      },
      name: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        value: useRoadbookStore().metadata.visitor[this.label] || ('' as Info | Infos),
        customOptions: [] as Array<Info>,
        values: [] as Array<Infos>,
        selectedValue: null as Infos | null,
      };
    },
    computed: {
      options(): Array<{ key: string; label: string }> {
        //Get all options
        const options: Array<{ key: string; label: string }> = [];
        const keys = Object.keys(this.values);

        keys.forEach((key) => {
          options.push({
            key,
            label: this.values[key],
          });
        });
        return options;
      },
      placeholder(): string {
        return this.name;
      },
      selectedCustomInfo(): string {
        //Search value in store
        const slug: string =
          useRoadbookStore().metadata.visitor.custom_client_infos != null
            ? useRoadbookStore().metadata.visitor.custom_client_infos[this.label] || ''
            : '';
        let text = '';
        if (slug != '') {
          const keys = this.values;

          keys.forEach((key: Infos) => {
            if (this.isString(key.value)) {
              if (key.value?.toString() == slug) {
                text = key.label?.default || '';
              }
            } else {
              key.value?.forEach((child) => {
                if (child.value && child.value == slug) {
                  text = child.label?.default || '';
                }
              });
            }
          });
        }
        return text;
      },
    },
    beforeMount(): void {
      //Copy arrays to avoid conf changes on modification of options content
      this.values = this.entries.slice();
      this.customOptions = this.options.slice();
    },
    mounted() {
      document.addEventListener('click', this.clickOutside);
      this.$refs.input.value = this.selectedCustomInfo;
      (document.querySelector('.' + this.label + ' .icons .close') as HTMLElement).style.opacity =
        '0';

      if (
        useRoadbookStore().metadata.visitor.custom_client_infos &&
        !useRoadbookStore().metadata.visitor.custom_client_infos[this.label]
      ) {
        useRoadbookStore().updateMetadata({
          category: 'visitor',
          field: 'custom_client_infos',
          value: null,
          key: this.label,
        });
      }
    },
    beforeUnmount() {
      document.removeEventListener('click', this.clickOutside);
    },
    methods: {
      clickOutside(e: MouseEvent) {
        // e : Event
        const ignoreClickOnMeElement: HTMLElement | null = document.querySelector(
          '.' + this.label + ' .select.custom-collapse',
        );
        const isClickInsideElement: boolean = document
          .getElementsByClassName(this.label)[0]
          .contains(e.target as HTMLElement);
        if (!isClickInsideElement) {
          ignoreClickOnMeElement ? (ignoreClickOnMeElement.style.opacity = '0') : null;
          ignoreClickOnMeElement ? (ignoreClickOnMeElement.style['pointer-events'] = 'none') : null;
          document.querySelector('.' + this.label + ' .icons .open')?.classList.remove('turn');
          (
            document.querySelector('.' + this.label + ' .icons .close') as HTMLElement
          ).style.opacity = '1';
          document.getElementsByClassName(this.label)[0].classList.remove('open');
          if (!this.$refs.input.value || this.$refs.input.value === '') {
            this.$refs.input.value = this.selectedCustomInfo;
          }
        }
      },
      selectCustomInfo(value: Infos): void {
        this.value = value;
        useRoadbookStore().updateMetadata({
          category: 'visitor',
          field: 'custom_client_infos',
          value: value.value ? value.value : value.label?.default,
          key: this.label,
        });
      },
      rotate(): void {
        if (this.$refs.turnable.$el.classList.value.includes('turn')) {
          this.closeSelect();
          document.getElementsByClassName(this.label)[0].classList.remove('open');
        } else {
          this.resetOptions();
          this.openSelect();
          document.getElementsByClassName(this.label)[0].classList.add('open');
        }
      },
      openSelect(): void {
        setTimeout(() => {
          //debounce on spam clickout
          const customcollapse: HTMLElement | null = document.querySelector(
            '.' + this.label + ' .custom-collapse',
          );
          if (customcollapse) {
            customcollapse.style.opacity = '1';
            customcollapse.style['pointer-events'] = 'inherit';
          }
          this.$refs.input.value = '';
          this.$refs.turnable.$el.classList.value =
            this.$refs.turnable.$el.classList.value + ' turn';
          (
            document.querySelector('.' + this.label + ' .icons .close') as HTMLElement
          ).style.opacity = '0';
          document.getElementsByClassName(this.label)[0].classList.add('open');
        }, 50);
      },
      closeSelect(): void {
        const customcollapse: HTMLElement | null = document.querySelector(
          '.' + this.label + ' .custom-collapse',
        );
        if (customcollapse) {
          customcollapse.style.opacity = '0';
          customcollapse.style['pointer-events'] = 'none';
        }
        if (this.$refs.input.value == '') {
          this.$refs.input.value = this.selectedCustomInfo;
        }
        this.$refs.turnable.$el.classList.value =
          this.$refs.turnable.$el.classList.value.replaceAll('turn', '');
        (document.querySelector('.' + this.label + ' .icons .close') as HTMLElement).style.opacity =
          '1';
      },
      updateOptions(): void {
        if (this.$refs.input.value.length < 1) {
          //set to default
          this.customOptions = this.options.slice();
          return;
        }
        //Update list available
        this.customOptions = [];
        let correspondingChildren: Array<Info> = [];
        const keys = Object.keys(this.values);
        keys.forEach((key) => {
          const label = { ...this.values[key].label }; //copy of objects
          if (!this.isString(this.values[key].value)) {
            correspondingChildren = this.values[key].value.map((a) => {
              return { ...a };
            });
            correspondingChildren = correspondingChildren.filter((entry: Info) => {
              return entry.label?.default
                ?.toLowerCase()
                .includes(this.$refs.input.value.toLowerCase());
            });
          }
          if (correspondingChildren.length >= 1) {
            //Add child and his parent
            const options: Infos = {};
            options.label = label;
            options.value = correspondingChildren;
            this.customOptions.push({
              key,
              label: options,
            });
          } else if (
            this.values[key].label.default
              .toLowerCase()
              .includes(this.$refs.input.value.toLowerCase())
          ) {
            //add parent
            this.customOptions.push({
              key,
              label: this.values[key],
            });
          }
        });
      },
      selectValue(entry: Infos): void {
        this.selectedValue = entry;
        this.$refs.input.value = entry.label?.default ? entry.label?.default : '';
        useRoadbookStore().setRoadbookModifiedDate();
        this.selectCustomInfo(this.selectedValue);
        document.getElementsByClassName(this.label)[0].classList.remove('open');
        this.closeSelect();
      },
      resetOptions(): void {
        this.$refs.input.value = '';
        (document.querySelector('.' + this.label + ' .icons .close') as HTMLElement).style.opacity =
          '0';
        this.updateOptions();
      },
      resetSearch(): void {
        this.$refs.input.value = '';
        useRoadbookStore().updateMetadata({
          category: 'visitor',
          field: 'custom_client_infos',
          value: null,
          key: this.label,
        });
      },
      isString(array: string | Info): boolean {
        return typeof array == 'string';
      },
    },
  });
</script>
<style lang="postcss">
  #custom-client-infos-field {
    @apply ml-[8px] mb-[16px] flex flex-col relative h-[38px];

    &.open {
      input {
        @apply rounded-br-none rounded-bl-none;
      }

      .icons {
        .right-arrow {
          @apply rounded-none rounded-tr-large;
        }
      }

      .custom-collapse {
        @apply rounded-t-none rounded-b-small;
      }
    }

    .custom-collapse {
      @apply absolute opacity-0 pointer-events-none w-full bg-white-grey transition duration-300 ease-in-out mt-[38px] max-h-[150px] overflow-auto;

      .option,
      .optgroup {
        @apply text-[16px];
        font-family: Poppins;
      }
      .option {
        @apply font-normal indent-[32px];

        &:checked {
          @apply text-primary bg-none;
        }
        &:hover {
          @apply cursor-pointer;
        }
        &.disabled {
          @apply cursor-default indent-0;

          &:hover {
            @apply text-real-black;
          }
        }
        @media (hover: hover) {
          &:hover {
            @apply text-primary;
          }
        }
        &.small {
          @apply indent-[8px];
        }
      }
      .optgroup {
        @apply font-extrabold indent-[16px];

        &:hover {
          @apply cursor-default;
        }
      }
    }

    input {
      @apply z-[2] w-[360px] h-[38px] rounded-tl-large rounded-tr-large rounded-br-large rounded-bl-large;
    }
    .icons {
      @apply justify-between absolute z-[3] cursor-pointer top-0 bottom-0 right-0 flex items-center;

      .right-arrow {
        @apply bg-primary h-full flex justify-center items-center rounded-r-[30px] py-0 pr-[18px] pl-[14px] transition duration-300 ease-in-out;
      }

      .close {
        @apply w-[14px] transition duration-300 ease-in-out pr-[10px];

        path {
          @apply stroke-white-grey stroke-[2px];
          fill: theme('colors.grey') !important;
        }
      }
      .open {
        @apply w-[10px] transition duration-300;
        transform: rotate(-90deg);

        &.turn {
          transform: rotate(90deg);
          animation: turn 0.5s;
        }
        path {
          stroke: theme('colors.white') !important;
        }
      }
    }
  }

  @-moz-keyframes turn {
    from {
      transform: rotate(-90deg);
    }
    to {
      transform: rotate(90deg);
    }
  }
  @-webkit-keyframes turn {
    from {
      transform: rotate(-90deg);
    }
    to {
      transform: rotate(90deg);
    }
  }

  @keyframes turn {
    from {
      transform: rotate(-90deg);
    }
    to {
      transform: rotate(90deg);
    }
  }
</style>
