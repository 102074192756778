<template>
  <!-- Top bar -->
  <div class="current-user">
    <!-- Back arrow bar -->
    <div
      class="cursor-pointer"
      @click="goBack"
    >
      <RcSvg
        v-if="!isOfficesPage"
        class="navbar-icon prev"
        name="next"
      />
    </div>

    <!-- Options: Avatar + Parameters Icon -->
    <div class="options cursor-pointer">
      <div class="advisor">
        <img
          class="current-user-avatar round"
          :src="user.image_url"
          @click="openUpdateUser"
        />
      </div>
      <RcSvg
        class="parameters"
        name="parametres"
        @click="toogleMenu"
      />
    </div>

    <!-- Dropdown Menu -->
    <div
      id="current-user-nav"
      :class="{ isOpen: isDropdownMenuOpened }"
    >
      <div class="pic"></div>
      <div
        class="current-user-nav__item"
        @click="goToAdvisors"
      >
        {{ $t('toogle.user', 'fr') }}
        <rc-svg
          name="right"
          class="svg-item"
        />
      </div>
      <div
        class="current-user-nav__item"
        @click="goToQRCode"
      >
        {{ $t('qr-code.title') }}
        <rc-svg
          name="right"
          class="svg-item"
        />
      </div>
      <div
        class="current-user-nav__item"
        @click="goToReport"
      >
        {{ $t('report.title') }}
        <rc-svg
          name="right"
          class="svg-item"
        />
      </div>
      <div
        class="current-user-nav__item"
        v-if="availableStats && !userIsAPro"
        @click="goToStatistics"
      >
        {{ $t('toogle.statistics') }}
        <rc-svg
          name="right"
          class="svg-item"
        />
      </div>
      <div
        class="current-user-nav__item"
        @click="disconnectIsOpen"
      >
        {{ $t('logout') }}
        <rc-svg
          name="right"
          class="svg-item"
        />
      </div>
    </div>
  </div>

  <!-- Logout Alert -->
  <rc-alert
    :show="isReconnectModalOpened"
    :title-text="$t('logout-title')"
    icon-name="off"
    :description="`${$t('logout_prompt')} </br><b>${$t('logout_prompt_span')}</b>`"
    :footer-confirm-button-text="$t('validate')"
    @confirm="logout"
    @cancel="isReconnectModalOpened = false"
    :footer-cancel-button-text="$t('cancel')"
    :icon-large="false"
    :icon-stroke="true"
  >
  </rc-alert>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue';
  import RcSvg from '@/components/medias/RcSvg.vue';
  import { useUserStore } from '@/store';
  import { useRoute, useRouter } from 'vue-router';
  import type { Ref } from 'vue';
  import RcAlert from '@/components/feedbacks/RcAlert.vue';

  //General
  const router = useRouter();
  defineProps({
    prevRoute: {
      type: String,
      default: '/',
    },
  });
  const route = useRoute();

  // Data
  const isDropdownMenuOpened: Ref<boolean> = ref(false);
  const isReconnectModalOpened: Ref<boolean> = ref(false);

  // Computed
  const user = computed(() => useUserStore().user);
  const isOfficesPage = computed(() => router.currentRoute.value.path === '/offices');
  const isDashboardPage = computed(() => router.currentRoute.value.path === '/dashboard');
  const availableStats = computed(() => useUserStore().hasStatisticsAccess);
  const userIsAPro = computed(() => useUserStore().user.product_shared || false);

  // Methods
  function openUpdateUser() {
    router.push('/updateUser');
    isDropdownMenuOpened.value = false;
  }

  function toogleMenu() {
    isDropdownMenuOpened.value = !isDropdownMenuOpened.value;
  }

  function goToAdvisors(): void {
    if (route.name !== 'advisors') {
      if (route.name !== 'offices' || !sessionStorage.getItem('opened_advisors_from')) {
        sessionStorage.setItem('opened_advisors_from', route.name as string);
      }
      if (useUserStore().noSessions) {
        useUserStore().noSessionsRoute = 'advisors';
      } else {
        router.push('/advisors');
      }
    }
    isDropdownMenuOpened.value = false;
  }

  function goToQRCode(): void {
    router.push('/qr-code');
    isDropdownMenuOpened.value = false;
  }

  function goToReport(): void {
    router.push('/report');
    isDropdownMenuOpened.value = false;
  }

  function disconnectIsOpen(): void {
    isReconnectModalOpened.value = !isReconnectModalOpened.value;
    isDropdownMenuOpened.value = false;
  }

  function goBack(): void {
    isDashboardPage.value ? router.push('/offices') : router.push('/dashboard');
    isDropdownMenuOpened.value = false;
  }

  async function goToStatistics(): Promise<void> {
    if (useUserStore().hasToDisconnect) {
      isReconnectModalOpened.value = true;
    } else {
      window.open(useUserStore().statisticsEskaladUrl);
    }
    isDropdownMenuOpened.value = false;
  }

  async function logout(): Promise<void> {
    await useUserStore().logout();
  }
</script>

<style lang="postcss">
  .current-user {
    @apply w-full bg-white min-h-[70px] flex border-b border-t-[0.5px] items-center justify-between;
    .navbar-icon {
      @apply fill-primary;

      path {
        @apply fill-primary;
      }

      &.prev {
        @apply rotate-180 ml-[30px];
      }
    }
    .options {
      @apply flex flex-row items-center;
      .advisor {
        @apply mr-4;
        .current-user-avatar {
          @apply w-[40px] h-[40px];
        }
      }
      .parameters {
        @apply w-[25px] h-[25px] flex items-center justify-center fill-primary mr-8;
      }
    }
    #current-user-nav {
      @apply absolute bg-white rounded-normal p-0 hidden flex-col top-[80px] right-[15px] w-[250px] duration-500 z-20;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

      svg.svg-item {
        @apply my-auto mr-standard ml-0 w-[10px];

        path {
          @apply stroke-[2] transition duration-[250ms] ease-in-out;
          stroke: theme('colors.grey') !important;
        }
      }

      &.isOpen {
        @apply flex duration-500;
        animation: fadeIn 300ms ease-in-out;
      }

      .pic {
        @apply bg-white w-[20px] h-[20px] z-10 absolute top-[-10px] right-[20px] transform rotate-45;
      }

      .current-user-nav__item {
        @apply p-[10px] w-full flex justify-between m-0 text-[18px] border-b-[1px] border-solid border-grey cursor-pointer transition duration-[250ms] ease-in-out z-20;

        @media (hover: hover) {
          &:hover {
            @apply transition duration-[400ms] ease-in-out text-primary;

            .svg-icon path {
              @apply transition duration-[400ms] ease-in-out;
              stroke: theme('colors.primary') !important;
            }
          }
        }
        &.disabled {
          @apply pointer-events-none text-primary;
          .svg-icon path {
            stroke: theme('colors.primary') !important;
          }
        }
      }

      .current-user-nav__item:last-child {
        @apply pb-[11px] border-b-0;
      }
    }
  }
</style>
