<template lang="pug">
.phone-field(
  ref="phoneField"
  v-if="prefixes.length"
  class="grow"
  :class="{'border-invalid' : !isValid(value), inputBackground}"
)
  .phone-input(class="w-full" :class="{ opened, invalid, 'isBorder' : isBorder, inputBackground }")
    v-select#phoneSelect(
      v-if="!checkOnDevice()[0]&&!checkOnDevice()[2]"
      :options="prefixes"
      :model-value="countryPrefix"
      label="name"
      :append-to-body="appendToBody"
      :clearable="false"
      :filter-by="filter"
      @update:model-value="newValue => prefixHandler(newValue)"
      :style="{'margin-left': '2px', inputBackground}"
      :calculate-position="(dropdownList, component, {width, top, left}) =>calculatePosition(dropdownList, component, {width, top, left})"
      )
      template(#selected-option="option")
        div.country-flag(:style="{ 'background-image': `url(${getCountryFlagUrl(option)})` }")
        span ({{ option.prefix.startsWith('+') ? '' : '+' }}{{ option.prefix }})
      template(#option="option")
        div.country-option(style="display: flex;")
          div.country-flag(:style="{ 'background-image': `url(${getCountryFlagUrl(option)})` }")
          span {{ option.name }} ({{ option.prefix.startsWith('+') ? '' : '+' }}{{ option.prefix }})
      template(#no-options)
        span {{ $t('no_results') }}
    v-select#phoneSelect-mobile(
      v-else
      :options="prefixes"
      :model-value="countryPrefix"
      label="name"
      :append-to-body="appendToBody"
      :clearable="false"
      :filter-by="filter"
      @update:model-value="newValue => prefixHandler(newValue)"
      :style="{'margin-left': '2px'}"
      :calculate-position="(dropdownList, component, {width, top, left}) =>calculatePosition(dropdownList, component, {width, top, left})"
      )
      template(#selected-option="option")
        div.country-flag-mobile(:style="{ 'background-image': `url(${getCountryFlagUrl(option)})` }")
        span ({{ option.prefix.startsWith('+') ? '' : '+' }}{{ option.prefix }})
      template(#option="option")
        div.country-option(style="display: flex;")
          div.country-flag(:style="{ 'background-image': `url(${getCountryFlagUrl(option)})` }")
          span {{ option.name }} ({{ option.prefix.startsWith('+') ? '' : '+' }}{{ option.prefix }})
      template(#no-options)
        span {{ $t('no_results') }}
    input.phone-number-input(
      type="tel"
      ref="phoneInput"
      @input="e => { inputHandler(e.target.value, debounce) }"
      :placeholder="placeholder"
      :value="phone"
      @keypress="limitChars($event)"
      @blur="toggleFocus"
      @focus="toggleFocus"
      pattern="^[0-9]+$"
      :class="{inputBackground}"
    )
    rc-svg.checked(
      v-if="value"
      :class="{'error-checked': !isValid(value), invalid}"
      :name="!isValid(value) ? 'croix' : 'check'")
</template>

<script lang="ts">
  import { defineComponent } from 'vue';

  import RcSvg from '@/components/medias/RcSvg.vue';
  import RcDebounce from '@/utils/mixins/RcDebounce.vue';
  import {
    AsYouType,
    parsePhoneNumberFromString,
    parseNumber,
    PhoneNumber,
    CountryCode,
    ParseNumberOptions,
  } from 'libphonenumber-js';
  import { useConfigStore } from '@/store/config';
  import { useRoadbookStore } from '@/store/roadbook';
  import { Contact } from '@/models/interfaces/Contact.interface';
  import { Config } from '@/models/interfaces/Config.interface';
  import mixin from '@/utils/mixins/RcMixin.vue';
  import { useMixin } from '@/utils/mixins/useMixin.mixin';

  export default defineComponent({
    setup() {
      return { useMixin };
    },
    components: {
      RcSvg,
    },
    mixins: [RcDebounce, mixin],
    props: {
      appendToBody: {
        type: Boolean,
        default: false,
      },
      empty: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: null,
      },
      valueInput: {
        type: String,
        default: null,
      },
      prefix: {
        type: String,
        default: null,
      },
      debounce: {
        type: Boolean,
        default: true,
      },
      invalid: {
        type: Boolean,
        required: false,
      },
      isBorder: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['input', 'changePrefix'],
    data() {
      return {
        error: false as boolean,
        country: '' as string,
        opened: false as boolean,
        value: null as string | null,
        focus: false as boolean,
      };
    },
    computed: {
      inputBackground(): string {
        if (useMixin().checkOnDevice()[0] || useMixin().checkOnDevice()[2]) return '!bg-white';
        else return '!bg-white-grey';
      },
      contact(): Contact {
        return useRoadbookStore().metadata.contact.filter((contact) => contact.phone != null)[0];
      },
      phone(): string | null {
        const numberString: string = this.value;
        if (numberString && numberString.length) {
          const numberObject: number | PhoneNumber | undefined = this.countryPrefix.code
            ? parsePhoneNumberFromString(numberString, this.countryPrefix.code)
            : 0;

          if (numberObject) {
            const nationalNumber: string = (numberObject as any).formatNational();
            const formated: string = new AsYouType(this.countryPrefix.code).input(nationalNumber);
            return formated || null;
          }
        }
        return numberString || null;
      },
      countryPrefix: {
        get(): { key: number; label: string } {
          const defaultCountry = useRoadbookStore().metadata.visitor.country || 'FRA';

          let countryCode: CountryCode | ParseNumberOptions =
            this.prefix || useConfigStore().cca2Codes[defaultCountry];

          if (!this.prefix && this.contact && this.contact.phone) {
            const parseNumberObject: any = parseNumber(this.contact.phone, countryCode) || {};
            if (parseNumberObject.country) {
              countryCode = parseNumberObject.country;
            }
          }
          return this.prefixes.length > 0
            ? this.prefixes.find((prefix: { code: string }) => prefix.code === countryCode)
            : countryCode;
        },
        set(value: string): void {
          this.$emit('changePrefix', value);
        },
      },
      prefixes(): Array<{ code: string; cca3code: string; name: string; prefix: string }> {
        const { countries }: Config = useConfigStore().config;
        const { cca2Codes } = useConfigStore();
        const phoneCodes: [string, string][] = useConfigStore().phoneCodes
          ? Object.entries(useConfigStore().phoneCodes)
          : [];

        if (phoneCodes.length > 0) {
          return phoneCodes
            .reduce(
              (
                prefixes: Array<{
                  code: string;
                  cca3code: string;
                  name: string;
                  prefix: string;
                }>,
                phoneCode,
              ) => {
                // phoneCode[0] => FRA
                // phoneCode[1] => 33

                if (!!phoneCode[0] && countries && countries[phoneCode[0]]) {
                  prefixes.push({
                    code: cca2Codes[phoneCode[0]]
                      ? cca2Codes[phoneCode[0]]
                      : phoneCode[0].substr(0, 2),
                    cca3code: phoneCode[0],
                    name: countries[phoneCode[0]],
                    prefix: phoneCode[1],
                  });
                }

                return prefixes;
              },
              [],
            )
            .sort(
              (
                a: { code?: string; cca3code: string; name?: string; prefix?: string },
                b: { code?: string; cca3code: string; name?: string; prefix?: string },
              ) => {
                // Algo de tri, les main_countries en premier, puis par ordre alphabétique
                const aIsMain = useConfigStore().config.main_countries?.length
                  ? a.cca3code && useConfigStore().config.main_countries?.includes(a.cca3code)
                  : false;
                const bIsMain = useConfigStore().config.main_countries?.length
                  ? b.cca3code && useConfigStore().config.main_countries?.includes(b.cca3code)
                  : false;
                if (aIsMain && bIsMain) {
                  return a.cca3code.localeCompare(b.cca3code);
                }
                if (aIsMain) return -1;
                if (bIsMain) return 1;
                return a.cca3code.localeCompare(b.cca3code);
              },
            );
        } else {
          console.warn('Missing phone codes in store');
          return [];
        }
      },
    },
    watch: {
      valueInput: {
        handler(): void {
          this.value = this.valueInput;
        },
      },
    },
    mounted(): void {
      const input: HTMLElement = this.$refs.phoneField.querySelector('input.vs__search');
      this.value = this.valueInput;

      !this.checkOnDevice()[0] ? input.addEventListener('focus', this.toogleOpened) : '';
      !this.checkOnDevice()[0] ? input.addEventListener('focusout', this.toogleOpened) : '';
    },
    methods: {
      toogleOpened(): void {
        this.opened = !this.opened;
      },
      filter(option: { name: string; prefix: string }, label: string, search: string): boolean {
        return `${option.name} ${option.prefix}`
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            search
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase(),
          );
      },
      inputHandler(value: string, wait: boolean): void {
        this.value = value;
        this.rcDebounce(
          () => {
            this.$emit('input', value);
          },
          wait ? 800 : 0,
        );
      },
      toggleFocus(): void {
        this.focus = !this.focus;
      },
      prefixHandler(value: string): void {
        this.$emit('changePrefix', value);
        if (this.phone) {
          this.$emit('input', this.phone);
        }
      },
      isValid(): string | boolean {
        const rawInput = this.value;
        if (rawInput && rawInput.length) {
          const numberObject = parsePhoneNumberFromString(rawInput, this.countryPrefix.code);
          if (numberObject && numberObject.isValid()) {
            return numberObject.format('E.164');
          }
          this.invalidate();
          return false;
        }
        return true;
      },
      invalidate(): void {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 1500);
      },
      getCountryFlagUrl(option: { code: string }): string {
        return !!option && !!option.code
          ? new URL(`/src/assets/flags/countries/${option.code}.png`, import.meta.url).href
          : '';
      },
      limitChars($event: { charCode: number; preventDefault: () => void }): boolean {
        if ($event.charCode >= 48 && $event.charCode <= 57) {
          return true;
        } else {
          $event.preventDefault();
          return false;
        }
      },
      calculatePosition(
        dropdownList: { style: { top: string; left: string } },
        component: HTMLElement,
        { top, left }: { top: string; left: string },
      ) {
        const rounded = parseInt(left.substr(0, left.length - 2)) % 1 >= 0.5 ? 1.5 : 2.75;
        dropdownList.style.top = Math.round(parseInt(top.substr(0, top.length - 2))) + 'px';
        dropdownList.style.left =
          Math.round(parseInt(left.substr(0, left.length - 2)) - rounded) + 'px';
      },
    },
  });
</script>

<style lang="postcss">
  .phone-field .phone-input.isBorder {
    @apply border-[1px] border-solid border-light-grey rounded-[0.6rem];
  }

  input.phone-number-input {
    @apply w-[200px] h-[34px] rounded-xl text-ellipsis;
    border: none !important;

    @media screen and (max-width: 950px) {
      @apply ml-2;
    }
  }
  .contact-field {
    @apply ml-0;
  }
  .checked {
    @apply absolute right-[15px] fill-primary h-[12px] transition-[fill] duration-300 ease-in-out;

    &.error-checked,
    &.invalid {
      @apply fill-error-color;
    }
  }

  .phone-field {
    @apply flex flex-col w-full rounded-md;
    align-items: flex-start !important;

    @media screen and (max-width: 950px) {
      @apply rounded-xl;
    }

    &.border-invalid {
      @apply border-error-color border-[2px] border-solid;
    }

    input[type='text']:focus-visible {
      @apply border-[2px] border-solid border-primary;
    }

    .phone-input {
      @apply relative border-[2px] border-solid border-white-grey mx-0 flex items-center rounded-md transition duration-300 ease-in-out h-[38px] w-full gap-2;

      &.opened {
        @apply rounded-b-none transition duration-300 ease-in-out;
      }

      > .tel {
        @apply fill-grey w-[44px] ml-[18px];
      }

      &.field-error {
        @apply border-[2px] border-solid border-error-color rounded-md transition duration-300;
      }

      .phone-number-input {
        @apply w-full overflow-hidden bg-transparent;
        border-radius: 0 theme('borderRadius.normal') theme('borderRadius.normal') 0 !important;
      }

      &.invalid {
        @apply border-error-color border border-solid;
      }

      #phoneSelect {
        @apply v-select-layer;
        .vs__dropdown-toggle {
          max-width: 160px !important;
          min-width: 160px !important;
          background-color: inherit;

          .vs__selected {
            @apply absolute top-0 z-[1];
            width: 100px !important;
          }

          .vs__actions {
            @apply bg-none bg-inherit rounded-none;
            svg {
              @apply h-[9px];

              path {
                stroke: theme('colors.grey') !important;
              }
            }
          }
        }

        &.vs--open {
          .vs__dropdown-toggle {
            input.vs__search {
              @apply flex-1;
              border-radius: theme('borderRadius.normal') !important;

              .country-flag {
                @apply hidden;
              }
            }
          }
        }
        .vs__dropdown-toggle {
          @apply cursor-pointer max-w-[140px] min-w-[140px] h-full;
          .vs__selected {
            @apply w-[90px];
          }
          input.vs__search {
            padding: 0 !important;
          }
        }
        .country-flag {
          @apply min-w-[24px] h-[16px] my-[4px] mr-[8px] ml-0;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
        span {
          margin: 4px 0;
        }
        .vs--single.vs--open .vs__selected {
          @apply hidden;
        }
      }
      #phoneSelect-mobile {
        @apply v-select-layer;
        .vs__dropdown-toggle {
          max-width: 80px !important;
          min-width: 80px !important;
          background-color: inherit;

          .vs__selected {
            @apply absolute top-0 z-[1];
            width: 100px !important;
          }

          .vs__actions {
            @apply bg-none bg-inherit rounded-none w-0;
            svg {
              @apply h-[9px];

              path {
                stroke: theme('colors.grey') !important;
              }
            }
          }
        }

        &.vs--open {
          .vs__dropdown-toggle {
            input.vs__search {
              @apply flex-1;
              border-radius: theme('borderRadius.normal') !important;

              .country-flag {
                @apply hidden;
              }
            }
          }
        }
        .vs__dropdown-toggle {
          @apply cursor-pointer max-w-[140px] min-w-[140px] h-full;
          .vs__selected {
            @apply w-[90px];
          }
          input.vs__search {
            padding: 0 !important;
          }
        }
        .country-flag-mobile {
          @apply w-[22px] h-[22px] my-[4px] mr-[8px] ml-0 rounded-full;
          background-size: 22px 22px;
          background-repeat: no-repeat;
          background-position: center;
        }
        span {
          margin: 4px 0;
        }
        .vs--single.vs--open .vs__selected {
          @apply hidden;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    height: 6px;
    background-color: transparent;
  }
  @media screen and (min-width: 950px) {
    .phone-field {
      @apply max-w-[400px];
    }
    .input.phone-number-input {
      @apply max-w-[250px];
    }
  }
  @media screen and (max-width: 950px) {
    .phone-field {
      @apply w-full;
      .phone-input {
        @apply w-full;
      }
    }
  }
</style>
