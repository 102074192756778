<template lang="pug">
#restart-btn
  #abandon-roadbook-open.click(@click="noConfirmMutable ? restart() : open()")
    slot(name="openButton")
      #abandon-roadbook-open-border
        rc-svg(name="croix")#abandon-roadbook-svg
      #abandon-roadbook-text {{ text }}
  // Leaving not finish Roadbook
  rc-alert(
:show="!isFinished && isLeaving && isOpen" :title-text="$t('be_careful', native)" :centered-title="true" :width="'900px'"
    :description="$t(isQualif ? 'roadbook_edit.abandon_warning_qualif' : 'roadbook_edit.abandon_warning', native)"
    :icon-name="'exclamation'" :icon-large="true"
    @confirm="deleteRoadbook()" @cancel="isOpen = false"
    :footer-cancel-button-text="$t('cancel', native)"  :footer-confirm-button-text="$t('abandon', native)")
  // Saving not finish Roadbook
  rc-alert(
:show="!isFinished && !isLeaving && isOpen" :title-text="$t('be_careful', native)" :centered-title="true" :max-width-desc="'700px'"
    :description="$t(isQualif ? 'roadbook_edit.saving_warning_qualif' : 'roadbook_edit.saving_warning', native)"
    :icon-name="'exclamation'" :icon-large="true"
    @confirm="restart()" @cancel="isOpen = false"
    :footer-cancel-button-text="$t('cancel', native)"  :footer-confirm-button-text="$t('save', native)")
  // Leaving completed Roadbook
  rc-alert(
:show="isFinished && isLeaving && isOpen" :title-text="$t('be_careful', native)" :centered-title="true" :max-width-desc="'700px'"
    :description="$t(isQualif ? 'roadbook_edit.abandon_confirm_warning_qualif' : 'roadbook_edit.abandon_confirm_warning', native)"
    :icon-name="'exclamation'" :icon-large="true"
    @cancel="isOpen = false" @confirm="restart()"
    :footer-cancel-button-text="$t('cancel', native)"  :footer-confirm-button-text="$t('abandon_confirm', native)")
  // Saving completed Roadbook
  rc-alert(
:show="isFinished && !isLeaving && isOpen" :title-text="$t('be_careful', native)" :centered-title="true" :max-width-desc="'700px'"
    :description="$t(isQualif ? 'roadbook_edit.saving_warning_qualif' : 'roadbook_edit.saving_warning', native)"
    :icon-name="'exclamation'" :icon-large="true"
    @cancel="isOpen = false" @confirm="restart()"
    :footer-cancel-button-text="$t('cancel', native)"  :footer-confirm-button-text="$t('save', native)")
</template>

<script setup lang="ts">
  import { ref, computed, watch } from 'vue';
  import RcAlert from '@/components/feedbacks/RcAlert.vue';
  import RcSvg from '@/components/medias/RcSvg.vue';
  import { useRoadbookStore, useTagManagerStore } from '@/store';
  import { useRouter } from 'vue-router';
  import { useMixin } from '@/utils/mixins/useMixin.mixin';

  // General
  const props = defineProps({
    text: {
      type: Object,
      default: () => ({}),
    },
    delete: {
      type: Boolean,
      default: true,
    },
    noConfirm: {
      type: Boolean,
      default: false,
    },
  });
  const { deleteRoadbook } = useMixin();
  const router = useRouter();
  const emit = defineEmits(['loading']);
  const isOpen = ref(false);
  const deleteMutable = ref(props.delete);
  const noConfirmMutable = ref(props.noConfirm);

  const roadbookStore = useRoadbookStore();
  const tagManagerStore = useTagManagerStore();

  const isFinished = computed(() => roadbookStore.metadata.is_finished);
  const isQualif = computed(() => roadbookStore.metadata.is_qualif);
  const isLeaving = ref(false);

  // Watchers
  watch(
    () => props.noConfirm,
    (newVal) => {
      noConfirmMutable.value = newVal;
    },
  );

  // Methods
  const open = () => {
    if (document.querySelector('#abandon-roadbook-text') || deleteMutable.value) {
      isLeaving.value = true;
    }
    isOpen.value = true;
  };

  const restart = async () => {
    emit('loading', true);
    if (isFinished.value) {
      deleteMutable.value = false;
    }
    if (isQualif.value) {
      deleteMutable.value = false;
      roadbookStore.updateMetadata({
        category: 'finished',
        field: null,
        value: true,
      });
    }
    tagManagerStore.sendEvent({
      event: deleteMutable.value ? 'roadbook_abandon' : 'roadbook_finish',
    });
    await roadbookStore.restart();
    router.push('dashboard');
    emit('loading', false);
  };
</script>

<style lang="postcss" scoped>
  #abandon-roadbook-text {
    font-size: 10px;
    margin-top: 5px;
    @apply text-error-color;
    @apply font-light;
    text-align: center;
    width: 65px;
    overflow: hidden;
    text-overflow: ellipsis;

    @media screen and (max-height: theme('screens.sm-desktop')) {
      #abandon-roadbook-text {
        @apply hidden;
      }
    }
  }

  .modal-buttons {
    display: flex;
    align-items: center;
    margin: 0 auto 2%;
    flex-direction: column-reverse;
    row-gap: 20px;

    .quit-rdbk {
      cursor: pointer;
      transition: color 300ms ease-in-out;

      @media (hover: hover) {
        &:hover {
          color: theme('colors.primary');
        }
      }
    }
  }

  #restart-btn {
    @apply flex items-center flex-col;
  }

  .abandon-wrapper {
    @apply flex flex-col;
  }

  .icon-error {
    @apply text-[80px] mt-[30px] w-auto text-center text-light-grey;
  }

  #abandon-roadbook-open {
    @apply text-[14px] flex items-center justify-center flex-col;

    @media (hover: hover) {
      &:hover {
        #abandon-roadbook-open-border {
          @apply opacity-100 outline-[0.5px] outline outline-error-color;
        }
      }
    }

    #abandon-roadbook-open-border {
      @apply border-solid border-[1.5px] border-error-color outline-[0.5px] outline outline-transparent h-[26px] w-[26px] opacity-80 flex items-center justify-center rounded-large;
      @apply transition duration-500 ease-in-out;
    }

    .open-button-wrapper {
      @apply flex items-center;
    }

    #abandon-roadbook-svg {
      @apply fill-error-color h-[11px];
    }

    .abandon-wrapper {
      @apply flex flex-col;
    }

    .icon-error {
      @apply text-[80px] mt-[30px] w-auto text-center text-light-grey;
    }

    #abandon-roadbook-dialog {
      @apply text-center text-[18px] z-[5];

      #abandon-title {
        @apply text-primary text-[35px] font-bold mt-[16px] mb-[32px];
      }
    }
  }
</style>
